import React, { Component } from "react";
import PropTypes from "prop-types";

import { Layout } from "antd";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { isNil } from "ramda";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import { createItemRest, resetCreateItem } from "../../actions/companies";
import { logout, updateUserPassword } from "../../actions/auth";
import { editUser } from "../../actions/company/users";
import { loadUser } from "../../actions/user";
import { getNotificationsList, getNotificationsStatus } from "../../actions/ncs/read";
import {
    deleteSingleNotification,
    deleteNotificationsList,
    updateSingleNotification,
    updateNotificationsList
} from "../../actions/ncs/write";

import Create from "../../components/manage-data/company/create";
import { errorBoundary } from "../../components/general/error-boundary";
import LayoutHeaderComponent from "../../components/manage-data/user/new-user-modal/layout-header-component";
import PageTitle from "../../components/general/page-title";

import { CreatePage } from "./styled";
import { FormattedMessage } from "react-intl";
import ItemRegistryView from "../child-apps/item-registry";
import { useFeature } from "../../hooks/use-feature";

class ItemCreate extends Component {
    constructor() {
        super();
        this.getNcsNotificationsList = this.getNcsNotificationsList.bind(this);
        this.getNcsNotificationsExist = this.getNcsNotificationsExist.bind(this);
        this.state = {
            itemCreated: false
        };
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        companies: PropTypes.object.isRequired,
        createItemStatus: PropTypes.object.isRequired,
        deleteSingleNotification: PropTypes.func.isRequired,
        deleteNotificationsList: PropTypes.func.isRequired,
        getNotificationsList: PropTypes.func.isRequired,
        getNotificationsStatus: PropTypes.func.isRequired,
        updateSingleNotification: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        notificationsExist: PropTypes.bool.isRequired,
        notificationsList: PropTypes.array,
        notificationsListStatus: PropTypes.object,
        user: PropTypes.object.isRequired
    };

    componentDidMount() {
        const { resetCreateItem } = this.props;
        resetCreateItem();
    }

    componentDidUpdate(prevProps) {
        const { createItemStatus } = this.props;

        if (prevProps.createItemStatus.started && createItemStatus.ended && !createItemStatus.error) {
            this.setState({ itemCreated: true });
        }
    }

    handleCreateItem(companyData) {
        const { createItemRest } = this.props;
        if (!isNil(companyData)) {
            let dataToInsert;

            let address = {
                types: ["REGISTERED_OFFICE"],
                streetName: companyData.streetName,
                streetNumber: companyData.streetNumber,
                city: companyData.city,
                province: companyData.province,
                zipCode: companyData.zipCode,
                country: companyData.country
            };

            dataToInsert = {
                base: {
                    identifier: {
                        taxId: companyData.taxRegion !== "IT" ? companyData.vatNumber : companyData.taxId,
                        vatNumber: companyData.vatNumber,
                        taxRegion: companyData.taxRegion
                    },
                    details: {
                        classifier: companyData.classifier,
                        description: companyData.description,
                        addresses: [address]
                    }
                }
            };
            createItemRest(dataToInsert);
        }
    }

    getNcsNotificationsList() {
        const { user, getNotificationsList } = this.props;
        if (user.user) {
            getNotificationsList(user.user.profile.ncsId);
        }
    }

    getNcsNotificationsExist() {
        const { user, getNotificationsStatus } = this.props;
        if (user.user) {
            getNotificationsStatus(user.user.profile.ncsId);
        }
    }

    render() {
        const {
            auth,
            companies,
            match,
            user,
            editUser,
            loadUser,
            deleteSingleNotification,
            deleteNotificationsList,
            updateSingleNotification,
            updateNotificationsList,
            notificationsExist,
            notificationsList,
            notificationsListStatus,
            updateUserPassword,
            updateUserPswStatus,
            userEditStatus,
            newItemRegistry,
            logout
        } = this.props;
        const { itemCreated } = this.state;

        const initialValues = {
            country: "IT",
            taxRegion: "IT",
            token: auth.loginAuth && auth.loginAuth.securityToken
        };

        return itemCreated ? (
            <Redirect to="/itemCreate/success" />
        ) : (
            user && (
                <Layout>
                    <LayoutHeaderComponent
                        auth={auth}
                        user={user}
                        logout={logout}
                        display={true}
                        editUser={editUser}
                        loadUser={loadUser}
                        notificationsExist={notificationsExist}
                        notificationsList={notificationsList}
                        notificationsListStatus={notificationsListStatus}
                        deleteSingleNotification={notificationId =>
                            deleteSingleNotification(user.user.profile.ncsId, notificationId)
                        }
                        deleteNotificationsList={() => deleteNotificationsList(user.user.profile.ncsId)}
                        getNotifications={this.getNcsNotificationsList}
                        getNotificationsExist={this.getNcsNotificationsExist}
                        updateNotificationsList={request => updateNotificationsList(user.user.profile.ncsId, request)}
                        updateSingleNotification={(notificationId, request) =>
                            updateSingleNotification(user.user.profile.ncsId, notificationId, request)
                        }
                        updateUserPsw={updateUserPassword}
                        updateUserPswStatus={updateUserPswStatus}
                        userEditStatus={userEditStatus}
                    />
                    {newItemRegistry ? (
                        <>
                            <button className="exit-item-create" onClick={() => this.props.history.push(`/`)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div style={{ position: "relative", height: "calc(100vh - 64px)" }}>
                                <ItemRegistryView />
                            </div>
                        </>
                    ) : (
                        <Layout.Content className="Root-content">
                            <PageTitle
                                title={<FormattedMessage id="c-company-create-modal.create-company" />}
                                onBack={() => this.props.history.goBack()}
                            />
                            <CreatePage>
                                <Create
                                    companies={companies}
                                    initialValues={initialValues}
                                    onCancel={() => this.props.history.push(`/${match.params.item}/dashboard`)}
                                    onCompanyCreation={data => this.handleCreateItem(data)}
                                />
                            </CreatePage>
                        </Layout.Content>
                    )}
                </Layout>
            )
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        companies: state.companies,
        createItemStatus: state.companies.createItem.status,
        notificationsExist: state.ncs.notifications.exist,
        notificationsList: state.ncs.notifications.data,
        notificationsListStatus: state.ncs.notifications.status,
        user: state.user,
        updateUserPswStatus: state.password.update.status || {},
        userEditStatus: state.manageUser.editUser.status || {}
    };
}

const composedHoc = compose(
    connect(mapStateToProps, {
        createItemRest,
        deleteSingleNotification,
        deleteNotificationsList,
        getNotificationsList,
        getNotificationsStatus,
        updateSingleNotification,
        updateNotificationsList,
        logout,
        resetCreateItem,
        editUser,
        loadUser,
        updateUserPassword
    }),
    errorBoundary
);

const El = composedHoc(ItemCreate);

export default ({ ...props }) => {
    const NEW_ITEM_REGISTRY = useFeature("NEW_ITEM_REGISTRY");

    return <El newItemRegistry={NEW_ITEM_REGISTRY} {...props} />;
};
