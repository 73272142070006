import React, { useEffect, useState } from "react";
import useElevioControls from "../../../hooks/use-elevio-controls";
import PortaleChildApp from "../../portale-child-app";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getCompanies } from "../../../actions/companies";

const ItemRegistryView = ({ getCompanies, companiesStatus }) => {
    const { hideWidget } = useElevioControls();
    const history = useHistory();
    const [to, setTo] = useState(null);

    useEffect(() => {
        hideWidget();
    }, [hideWidget]);

    useEffect(() => {
        if (companiesStatus.ended) {
            history.push(to);
        }
    }, [companiesStatus.ended, history, to]);

    useEffect(() => {
        const listener = message => {
            if (message.data && typeof message.data === "string") {
                const parsedMessage = JSON.parse(message.data);

                if (parsedMessage.type === "NAVIGATE" && typeof parsedMessage.to === "string") {
                    getCompanies(null, 0, 10);
                    setTo(parsedMessage.to);
                }
            }
        };

        window.addEventListener("message", listener);

        return () => {
            window.removeEventListener("message", listener);
        };
    }, [getCompanies]);

    return <PortaleChildApp appId="rnd:core:item-registry:app" serviceId="rnd:core:item-registry:app" />;
};

export default connect(
    state => ({
        companiesStatus: state.companies.total.status
    }),
    {
        getCompanies
    }
)(ItemRegistryView);
