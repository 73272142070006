import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";

import "./style.css";
import "../general/dashboard-service-card/style.css";
import { isAdmin } from "../../utils/app-availability";
import CompanyInfo from "./company-info";
import AppCard from "./app-card";
import SuggestedAppCard from "./suggested-app-card";
import {
    Title,
    CardsContainer,
    Container,
    CompanyInfoContainer,
    ActiveCategoriesContainer,
    SuggestedCategoriesContainer,
    CloudAppsContainer,
    TsLogoContainer,
    TalksAdvertisingBannerWrapper
} from "./styled";
import { TS_DIGITAL_IMAGES } from "../../config";
import TalksAdvertisingBanner from "../talks-banner/components/talks-advertising-banner";
import { useFeature } from "../../hooks/use-feature";
import { mergeStatus } from "../../utils/state-utils/get-merged-status";

const Dashboard = ({
    auth,
    company,
    companyUsers,
    configTsPayStatus,
    coreMarketingTerms,
    esmDiscover,
    esmOpenApp,
    findOwnManagerConnections,
    hasActiveCramConnection,
    hasStudio,
    itemApps,
    partecipateTalks,
    provisioningInfo,
    rejectionMessage,
    resetCompanyLoad,
    user,
    logoUrl,
    logoFinishedLoading,
    tsPayInfo,
    hasActiveArcService
}) => {
    const { item } = useParams();

    useEffect(() => {
        findOwnManagerConnections({ active: true, deleted: false, managedIds: item, unpaged: true, appId: "CRAM" }, {});
    }, [findOwnManagerConnections, item]);
    const isItemAdmin = useMemo(() => isAdmin(user.roles, company.base.id), [user.roles, company.base.id]);

    const talksBannerFeatureFlag = useFeature("TALKS_BANNER");
    const talksStartTime = new Date("2023-05-31T00:00:01").getTime();
    const talksLinkStartTime = new Date("2023-05-30T00:00:01").getTime();
    const talksEndTime = new Date("2023-06-07T00:00:00").getTime();
    const currentTime = new Date().getTime();
    const talksBannerEnabled =
        (talksBannerFeatureFlag && coreMarketingTerms) ||
        (coreMarketingTerms && currentTime >= talksStartTime && currentTime <= talksEndTime);

    const talksLinkEnabled = currentTime >= talksLinkStartTime && currentTime <= talksEndTime;

    const [isTalksBannerClosedState, setTalksBannerClosedState] = useState(
        sessionStorage.getItem("talksAdvertisingBannerClosed") === "true"
    );

    const configRoutes = {
        ARC: "/storage",
        EIP: "/billing",
        "GDPR-LOG": "/gdprlog",
        TEL: "/fulfillments",
        EXP: "/expense-reports",
        TSPAY: "/tspay",
        EBS: "/tspay"
    };

    const exploreRoutes = {
        AGRO: {
            external: false,
            to: `/${item}/apps/agro/details`
        },
        ARC: {
            external: false,
            to: `/${item}/apps/storage/details`
        },
        DBX: {
            external: false,
            to: `/${item}/apps/console-digitale/details`
        },
        EIP: {
            external: false,
            to: `/${item}/apps/fatturazione/details`
        },
        "GDPR-LOG": {
            external: false,
            to: `/${item}/apps/gdprlog/details`
        },
        PPL: {
            external: false,
            to: `/${item}/apps/people-app/details`
        },
        PRIVACY: {
            external: false,
            to: `/${item}/apps/privacy/details`
        },
        SIG: {
            external: false,
            to: `/${item}/apps/firma/details`
        },
        TEL: {
            external: false,
            to: `/${item}/apps/adempimenti/details`
        },
        EBS: {
            external: false,
            to: `/${item}/apps/tspay/details`
        },
        EXP: {
            external: false,
            to: `/${item}/apps/expense-reports/details`
        },
        EIT: {
            external: false,
            to: `/${item}/apps/invoicetrading/details`
        },
        ESM: {
            external: false,
            to: `/${item}/apps/spendmanagement/details`
        },
        CRAM: {
            external: false,
            to: `/${item}/apps/creditrisk/details`
        },
        NIUMABONUS: {
            external: false,
            to: `/${item}/apps/niumabonus/details`
        },
        SPID: {
            external: false,
            to: `/${item}/apps/spid/details`
        },
        TSPAY: {
            external: false,
            to: `/${item}/apps/tspay/details`
        },
        BIS: {
            external: false,
            to: `/${item}/apps/businessinformation/details`
        },
        DRAG: {
            external: false,
            to: `/${item}/apps/dragandtag/details`
        },
        SCF: {
            external: false,
            to: `/${item}/apps/deeptier/details`
        },
        CYBER: {
            external: false,
            to: `/${item}/apps/cyber/details`
        }
    };

    const cloudRoutes = {
        AGRO: {
            external: false,
            to: `/${item}/apps/agro/details`
        },
        PRIVACY: {
            external: true,
            to: "https://www.teamsystem.com/store/adempimenti/privacy-in-cloud/prezzi/?utm_source=Portale-TS-Digital&utm_medium=referral&utm_campaign=software-experience"
        },
        EXP: {
            external: true,
            to: "https://www.teamsystem.com/note-spese-mobile-ts-enterprise"
        },
        PPL: {
            external: true,
            to: "https://www.teamsystem.com/hr/app-mobile-teamsystem-hr/"
        },
        BDG: {
            external: true,
            to: "https://www.teamsystem.com/store/fatturazione-e-contabilita/bilancio-in-cloud/"
        }
    };

    let activeCategories = [];
    let disabledCategories = [];
    let cloudCategories = [];
    let digitalCategories = [];

    /**
     * A card should be considered to be active if either:
     * - The service is active for the item and the user has the appropriate permissions
     * - The service is ARC, the provisioning process has started and the user has the appropriate permissions
     * - The service is GDPR-LOG and the user is an item administrator
     */
    Object.values(itemApps).forEach(itemApp => {
        if (itemApp.services.find(x => x.availableToUser && (x.activeForItem || x.active))) {
            if (itemApp.id === "CRAM" && hasActiveCramConnection && !itemApp.services[0].ownedByItem) {
                disabledCategories.push(itemApp);
            } else {
                activeCategories.push(itemApp);
            }
        } else if (
            (itemApp.id === "ARC" &&
                isItemAdmin &&
                (provisioningInfo.status === "initialized" ||
                    provisioningInfo.status === "uploaded" ||
                    provisioningInfo.status === "downloaded" ||
                    // TODO rimuovere il check sullo stato validated dopo aver fixato il flow di attivazione lato backend
                    provisioningInfo.status === "rejected" ||
                    provisioningInfo.status === "validated" ||
                    provisioningInfo.status === "pending")) ||
            (itemApp.id === "GDPR-LOG" && isItemAdmin)
        ) {
            activeCategories.push(itemApp);
        } else if (itemApp.services.find(x => x.active && !x.availableToUser && !x.activeForItem)) {
            disabledCategories.push(itemApp);
        }

        cloudCategories = Object.values(disabledCategories).filter(c => c.inCloud);
        digitalCategories = Object.values(disabledCategories).filter(c => !c.inCloud);
    });

    const storageConfigurationAvailable = app => {
        // We only need to hide ARC service from ARC app, only CCT-FLOW on ARC app should have configuration
        if (!app || !app.services || app.id !== "ARC") {
            return true;
        }

        const newArcService = app.services.find(service => service.id === "ARC");

        return !(newArcService && newArcService.activeForItem);
    };

    const companyStatus = mergeStatus(company.base.status);

    return (
        <Container>
            <CompanyInfoContainer>
                <CompanyInfo
                    company={company}
                    configTsPayStatus={configTsPayStatus}
                    rejectionMessage={rejectionMessage}
                    user={{ user }}
                    users={companyUsers}
                    logoUrl={logoUrl}
                    logoFinishedLoading={logoFinishedLoading}
                    hasStudio={hasStudio}
                    isTalksBannerClosed={isTalksBannerClosedState}
                    isTSPayOrEBSActive={!!activeCategories.find(c => c.id === "EBS" || c.id === "TSPAY")}
                    onClickManageButton={resetCompanyLoad}
                    partecipateTalks={partecipateTalks}
                    provisioningInfo={provisioningInfo}
                    talksBannerEnabled={talksBannerEnabled}
                    talksLinkEnabled={talksLinkEnabled}
                    tsPayInfo={tsPayInfo}
                    hasActiveArcService={hasActiveArcService}
                />
            </CompanyInfoContainer>
            {activeCategories.length > 0 && (
                <ActiveCategoriesContainer>
                    <Title>
                        <FormattedMessage id="c-company-dashboard.section-title.my-apps" />
                    </Title>
                    <CardsContainer>
                        {activeCategories.map(activeCategory => (
                            <AppCard
                                esmOpenApp={esmOpenApp}
                                key={activeCategory.id}
                                companyStatus={companyStatus}
                                configRoute={
                                    configRoutes[activeCategory.id] &&
                                    storageConfigurationAvailable(activeCategory) &&
                                    `/${item}/services${configRoutes[activeCategory.id]}`
                                }
                                name={activeCategory.id}
                                hasApp={activeCategory.hasApp}
                                token={auth.loginAuth.securityToken}
                                services={activeCategory.services}
                            />
                        ))}
                    </CardsContainer>
                </ActiveCategoriesContainer>
            )}
            {isItemAdmin && (
                <>
                    {digitalCategories.length > 0 && (
                        <SuggestedCategoriesContainer>
                            <TsLogoContainer>
                                <img src={`${TS_DIGITAL_IMAGES}logo-darkblue.svg`} alt="logo-dark-blue" />
                            </TsLogoContainer>
                            <Title>
                                <FormattedMessage id="c-company-dashboard.section-title.suggested-apps" />
                            </Title>
                            <CardsContainer>
                                {digitalCategories.map(digitalCategory => (
                                    <SuggestedAppCard
                                        esmDiscover={esmDiscover}
                                        key={digitalCategory.id}
                                        name={digitalCategory.id}
                                        isNew={digitalCategory.newApp}
                                        configRoute={exploreRoutes[digitalCategory.id]}
                                    />
                                ))}
                            </CardsContainer>
                        </SuggestedCategoriesContainer>
                    )}
                    {company.base.details.classifier !== "BUILDING" && cloudCategories.length > 0 && (
                        <CloudAppsContainer>
                            <Title>
                                <FormattedMessage id="c-company-dashboard.section-title.other-apps" />
                            </Title>
                            <CardsContainer>
                                {cloudCategories.map(cloudCategory => (
                                    <SuggestedAppCard
                                        key={cloudCategory.id}
                                        name={cloudCategory.id}
                                        isNew={cloudCategory.newApp}
                                        configRoute={cloudRoutes[cloudCategory.id]}
                                    />
                                ))}
                            </CardsContainer>
                        </CloudAppsContainer>
                    )}
                </>
            )}
            {talksBannerEnabled && !isTalksBannerClosedState && (
                <TalksAdvertisingBannerWrapper>
                    <TalksAdvertisingBanner
                        hasStudio={hasStudio}
                        onClose={() => {
                            sessionStorage.setItem("talksAdvertisingBannerClosed", "true");
                            setTalksBannerClosedState(true);
                        }}
                        partecipateTalks={partecipateTalks}
                        talksLinkEnabled={talksLinkEnabled}
                    />
                </TalksAdvertisingBannerWrapper>
            )}
        </Container>
    );
};

Dashboard.propTypes = {
    company: PropTypes.object.isRequired,
    configTsPayStatus: PropTypes.string,
    coreMarketingTerms: PropTypes.bool,
    displayInfoToast: PropTypes.func,
    esmDiscover: PropTypes.func.isRequired,
    hasStudio: PropTypes.bool,
    itemApps: PropTypes.object.isRequired,
    partecipateTalks: PropTypes.func,
    provisioningInfo: PropTypes.object,
    rejectionMessage: PropTypes.string,
    resetCompanyLoad: PropTypes.func.isRequired,
    user: PropTypes.object,
    hasActiveArcService: PropTypes.bool
};

export default Dashboard;
