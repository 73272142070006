import React, { Component } from "react";
import PropTypes from "prop-types";

import { Layout } from "antd";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import { logout, updateUserPassword } from "../../../actions/auth";
import { editUser } from "../../../actions/company/users";
import { loadUser } from "../../../actions/user";
import { getNotificationsList, getNotificationsStatus } from "../../../actions/ncs/read";
import {
    deleteSingleNotification,
    deleteNotificationsList,
    updateSingleNotification,
    updateNotificationsList
} from "../../../actions/ncs/write";

import LayoutHeaderComponent from "../../../components/manage-data/user/new-user-modal/layout-header-component";
import SuccessPage from "../../../components/general/accordion-stepper/success-page";
import { errorBoundary } from "../../../components/general/error-boundary";

class ItemSuccess extends Component {
    constructor() {
        super();
        this.getNcsNotificationsList = this.getNcsNotificationsList.bind(this);
        this.getNcsNotificationsExist = this.getNcsNotificationsExist.bind(this);
    }

    static propTypes = {
        auth: PropTypes.object.isRequired,
        createItemStatus: PropTypes.object.isRequired,
        deleteSingleNotification: PropTypes.func.isRequired,
        deleteNotificationsList: PropTypes.func.isRequired,
        getNotificationsList: PropTypes.func.isRequired,
        getNotificationsStatus: PropTypes.func.isRequired,
        updateSingleNotification: PropTypes.func.isRequired,
        updateNotificationsList: PropTypes.func.isRequired,
        notificationsExist: PropTypes.bool.isRequired,
        notificationsList: PropTypes.array,
        notificationsListStatus: PropTypes.object,
        match: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    };

    getNcsNotificationsList() {
        const { user, getNotificationsList } = this.props;
        if (user.user) {
            getNotificationsList(user.user.profile.ncsId);
        }
    }

    getNcsNotificationsExist() {
        const { user, getNotificationsStatus } = this.props;
        if (user.user) {
            getNotificationsStatus(user.user.profile.ncsId);
        }
    }

    render() {
        const {
            auth,
            createItemStatus,
            history,
            match,
            deleteSingleNotification,
            deleteNotificationsList,
            updateSingleNotification,
            updateNotificationsList,
            notificationsExist,
            notificationsList,
            notificationsListStatus,
            user,
            editUser,
            loadUser,
            updateUserPassword,
            updateUserPswStatus,
            userEditStatus,
            logout
        } = this.props;

        return createItemStatus.ended ? (
            <Layout>
                <LayoutHeaderComponent
                    auth={auth}
                    user={user}
                    logout={logout}
                    display={true}
                    editUser={editUser}
                    loadUser={loadUser}
                    notificationsExist={notificationsExist}
                    notificationsList={notificationsList}
                    notificationsListStatus={notificationsListStatus}
                    deleteSingleNotification={notificationId =>
                        deleteSingleNotification(user.user.profile.ncsId, notificationId)
                    }
                    deleteNotificationsList={() => deleteNotificationsList(user.user.profile.ncsId)}
                    getNotifications={this.getNcsNotificationsList}
                    getNotificationsExist={this.getNcsNotificationsExist}
                    updateNotificationsList={request => updateNotificationsList(user.user.profile.ncsId, request)}
                    updateSingleNotification={(notificationId, request) =>
                        updateSingleNotification(user.user.profile.ncsId, notificationId, request)
                    }
                    updateUserPsw={updateUserPassword}
                    updateUserPswStatus={updateUserPswStatus}
                    userEditStatus={userEditStatus}
                />
                <Layout.Content className="Root-content">
                    <SuccessPage
                        onBackButtonClick={() => {
                            history.push("/");
                        }}
                        title={"c-offices-success.title"}
                        subtitle={"c-offices-success.subtitle"}
                        buttonText={"c-item-success.button"}
                    />
                </Layout.Content>
            </Layout>
        ) : (
            <Redirect to={`/${match.params.item}/dashboard`} />
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        companies: state.companies,
        createItemStatus: state.companies.createItem.status,
        notificationsExist: state.ncs.notifications.exist,
        notificationsList: state.ncs.notifications.data,
        notificationsListStatus: state.ncs.notifications.status,
        user: state.user,
        updateUserPswStatus: state.password.update.status || {},
        userEditStatus: state.manageUser.editUser.status || {}
    };
}

const composedHoc = compose(
    connect(mapStateToProps, {
        logout,
        editUser,
        deleteSingleNotification,
        deleteNotificationsList,
        getNotificationsList,
        getNotificationsStatus,
        updateSingleNotification,
        updateNotificationsList,
        loadUser,
        updateUserPassword
    }),
    errorBoundary
);

export default composedHoc(ItemSuccess);
